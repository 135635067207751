var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        {
          attrs: {
            title: _vm.$t("common.detail-text", {
              text: _vm.$t("lbl_sparepart_request_form")
            }),
            loading: _vm.loading
          }
        },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("h3", [
                    _vm._v(_vm._s(_vm.$t("lbl_sparepart_request_form")))
                  ]),
                  _c(
                    "a-descriptions",
                    { attrs: { layout: "vertical", column: 5, size: "small" } },
                    [
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_document_number") } },
                        [_vm._v(" " + _vm._s(_vm.dtDetail.documentNo) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_request_date") } },
                        [_vm._v(" " + _vm._s(_vm.dtDetail.requestDate) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_branch") } },
                        [_vm._v(" " + _vm._s(_vm.dtDetail.branchName) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_mechanic") } },
                        [_vm._v(" " + _vm._s(_vm.dtDetail.mechanicName) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_description") } },
                        [_vm._v(" " + _vm._s(_vm.dtDetail.description) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_status") } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.toTitlecase(_vm.dtDetail.status)) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("h3", [_vm._v(_vm._s(_vm.$t("lbl_customer")))]),
                  _c(
                    "a-descriptions",
                    { attrs: { column: 3, layout: "vertical", size: "small" } },
                    [
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_customer") } },
                        [_vm._v(" " + _vm._s(_vm.dtDetail.customerName) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_address") } },
                        [_vm._v(" " + _vm._s(_vm.dtDetail.address) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.hasRefs
                ? _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c("h3", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("common.reference-text", {
                                text: _vm.$t("lbl_customer")
                              })
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "a-descriptions",
                        {
                          attrs: {
                            column: 3,
                            layout: "vertical",
                            size: "small"
                          }
                        },
                        [
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: _vm.$t("lbl_customer") } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.dtDetail.customerRef || "-") +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: _vm.$t("lbl_address") } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.dtDetail.addressRef || "-") +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card",
        {
          staticClass: "mt-4",
          attrs: { title: _vm.$t("lbl_unit_code"), loading: _vm.loading }
        },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _vm.hasRefs && _vm.dtDetail.units.length
                ? _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c("h3", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("common.reference-text", {
                                text: _vm.$t("lbl_unit_code")
                              })
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "a-descriptions",
                        {
                          attrs: {
                            layout: "vertical",
                            bordered: "",
                            size: "small"
                          }
                        },
                        [
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: _vm.$t("lbl_unit_code") } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.dtDetail.units[_vm.page - 1]
                                      .unitCodeRef || "-"
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: _vm.$t("lbl_brand") } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.dtDetail.units[_vm.page - 1].brandRef ||
                                      "-"
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: _vm.$t("lbl_type") } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.dtDetail.units[_vm.page - 1].typeRef ||
                                      "-"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("h3", [_vm._v(_vm._s(_vm.$t("lbl_unit_code")))]),
                  _c("a-table", {
                    attrs: {
                      "data-source": _vm.dtDetail.units,
                      columns: _vm.columns,
                      "row-key": function(r, i) {
                        return i
                      },
                      size: "small",
                      "row-class-name": function(_record, index) {
                        return index % 2 ? "bg-white" : "bg-gray-light"
                      },
                      pagination: {
                        pageSize: 1,
                        current: _vm.page,
                        onChange: function(current) {
                          return (_vm.page = current)
                        }
                      },
                      "default-expand-all-rows": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "expandedRowRender",
                        fn: function(record) {
                          return [
                            _c("a-table", {
                              attrs: {
                                size: "small",
                                "data-source": record.items,
                                columns: _vm.innerColumns,
                                pagination: false,
                                "row-key": function(r, i) {
                                  return i
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "partName",
                                    fn: function(text, part) {
                                      return [
                                        part.attachmentFile
                                          ? _c("a-icon", {
                                              attrs: { type: "file-image" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.showImage(part)
                                                }
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(
                                          " " +
                                            _vm._s(part.partName || "-") +
                                            " "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3 text-right" },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleBack } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                  ]),
                  _vm.$can("create", "work-order") && _vm.canCreateWorkOrder
                    ? _c(
                        "RouterLink",
                        {
                          attrs: {
                            to: {
                              name:
                                "logistic.spare-part-request.work-order.create",
                              params: { id: _vm.dtDetail.sparePartRequestId }
                            }
                          }
                        },
                        [
                          _c("a-button", { attrs: { type: "primary" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("common.create-text", {
                                    text: _vm.$t("lbl_work_order")
                                  })
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("vue-light-box", {
        attrs: {
          visible: _vm.imageViewData.visible,
          imgs: _vm.imageViewData.url
        },
        on: { hide: _vm.closeImage }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
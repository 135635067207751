














































































































































import dateFormat from "@/filters/date.filter";
import quantityFilter from "@/filters/quantity.filter";
import { toTitlecase } from "@/helpers/common";
import { DATE_TIME_DEFAULT_FORMAT } from "@/models/constants/date.constant";
import { EnumSparePartRequestStatus } from "@/models/enums/SparePartRequest.enum";
import {
  SparePartDetailHeaderResDto,
  SparePartDetailItemResDto,
} from "@/models/interface/sparepart-request";
import { sparePartRequestService } from "@/services/sparepart-request.service";
import { StringUtils } from "@/utils";
import { Column } from "ant-design-vue/types/table/column";
import Vue from "vue";

export default Vue.extend({
  name: "DetailSparePartRequest",
  props: {
    id: String,
  },
  data() {
    return {
      loading: false,
      page: 1,
      imageViewData: {
        visible: false,
        url: null as string | null,
      },
      dtDetail: {
        address: "",
        addressRef: "",
        branchId: "",
        branchName: "",
        customerId: "",
        customerName: "",
        customerRef: "",
        description: "",
        documentNo: "",
        mechanicId: "",
        mechanicName: "",
        requestDate: "",
        sparePartRequestId: "",
        status: "" as EnumSparePartRequestStatus,
        units: [],
      } as SparePartDetailHeaderResDto,
      columns: [
        {
          title: this.$t("lbl_unit_code"),
          dataIndex: "unitCode",
        },
        {
          title: this.$t("lbl_equipment"),
          dataIndex: "equipment",
          key: "equipment",
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_brand"),
          dataIndex: "brand",
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_type"),
          dataIndex: "type",
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_serial_number"),
          dataIndex: "serialNumber",
          key: "serialNumber",
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_model"),
          dataIndex: "model",
          key: "model",
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_spec"),
          dataIndex: "spec",
          key: "spec",
          customRender: (text: string) => text || "-",
        },
      ] as Column[],
      innerColumns: [
        {
          title: this.$t("lbl_part_reference"),
          dataIndex: "partName",
          scopedSlots: { customRender: "partName" },
        },
        {
          title: this.$t("common.reference-text", { text: this.$t("lbl_uom") }),
          dataIndex: "uomReference",
          customRender: text => text || "-",
        },
        {
          title: this.$t("lbl_quantity"),
          dataIndex: "qty",
          customRender: text => quantityFilter(text),
        },
        {
          title: this.$t("lbl_note"),
          dataIndex: "note",
          customRender: text => text || "-",
        },
      ] as Column[],
    };
  },
  computed: {
    hasRefs(): boolean {
      return !!this.dtDetail.customerRef;
    },
    canCreateWorkOrder(): boolean {
      return (
        StringUtils.compare(this.dtDetail.status, "UNPROCESSED") ||
        StringUtils.compare(this.dtDetail.status, "PARTIALLY_PROCESSED")
      );
    },
  },
  mounted() {
    this.getDetail(this.id);
  },
  methods: {
    toTitlecase,
    handleBack() {
      this.$router.push({ name: "logistic.sparepart-request" });
    },
    closeImage() {
      this.imageViewData.visible = false;
      this.imageViewData.url = null;
    },
    showImage(part: SparePartDetailItemResDto): void {
      this.imageViewData.url = part.attachmentFile;
      this.imageViewData.visible = true;
    },
    getDetail(id: string) {
      this.loading = true;
      sparePartRequestService
        .getDetail(id)
        .then(response => {
          this.dtDetail = {
            ...response,
            requestDate: dateFormat(
              response.requestDate,
              DATE_TIME_DEFAULT_FORMAT
            ),
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
